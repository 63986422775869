"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', false, /\.(svg|webp)$/));
class TrigonometryCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.imgCache = imgCache;
        this.x = 'angle';
        this.kind = 'sin';
    }
    $onInit() {
    }
    setKind(kind) {
        this.kind = kind;
        if (this.trigForm)
            this.clear(this.trigForm);
    }
    angleToRadians(angle) {
        return angle * (Math.PI / 180);
    }
    radianToDegree(radian) {
        return radian * (180 / Math.PI);
    }
    submitForm($form) {
        if ($form.$valid && (this.angle != undefined)) {
            let value = undefined;
            if (this.x == 'angle') {
                value = this.angleToRadians(this.angle);
            }
            else if (this.x == 'radian') {
                value = this.angle;
            }
            if (value != undefined) {
                const result = {
                    kind: this.kind,
                    x: this.x,
                    inRad: value,
                    inDegree: this.x == 'radian' ? this.radianToDegree(value) : this.angle
                };
                if (this.kind == 'sin') {
                    result.value = Math.sin(value);
                }
                else if (this.kind == 'cos') {
                    result.value = Math.cos(value);
                }
                else if (this.kind == 'tan') {
                    result.value = Math.tan(value);
                }
                else if (this.kind == 'cot') {
                    result.value = 1 / Math.tan(value);
                }
                else if (this.kind == 'sec') {
                    result.value = 1 / Math.cos(value);
                }
                else if (this.kind == 'csc') {
                    result.value = 1 / Math.sin(value);
                }
                this.result = result;
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.angle = undefined;
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.value);
        }
    }
}
TrigonometryCtrl.$inject = ['$scope', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameTrigonometry', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TrigonometryCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('trigonometry/');
    }]);
